@import "../../../styles/variables.scss";
.cardsCounter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    .active-cards {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        p {
            font-size: 30px;
        }
    }

    .cards-chart {
        width: 100%;
        height: 250px;
        background-color: white;
    }
}