.group-buttons {
    display: flex;
    gap: 30px;
    font-size: 20px;

    label {
        border-radius: 15%;
        background-color: rgba(156, 156, 156, 0.3);
        padding: 10px;

        input[type="radio"] {
            width: 30px;
            height: 30px;
            border-radius: 50%;

        }
    }
}