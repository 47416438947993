.group-buttons {
    margin-top: 3rem;

    button {
        border-radius: 10%;
        background-color: rgba(156, 156, 156, 0.3);
        color: white;
        border: none;
        font-size: 30px;
        padding: 0.5rem;
    }

    .active {
        color: red
    }
}
.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px; /* Регулирайте както е необходимо */
  }
  
  .green-dot {
    background-color: green;
  }
  
  .red-dot {
    background-color: red;
  }