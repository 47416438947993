// BG
$main-bg: #2a3447;
$soft-bg: #384256;
$dark-bg: #222b3c;
//TEXT
$main-color: white;
$soft-color: #ddd;
$dark-color: #2a3447;



$font-weight-bold: 800;
$font-size-small: 1.2rem;
$font-size-normal: 1.6rem;
$font-size-between: 2rem;
$font-size-medium: 2.4rem;
$font-size-big: 3.2rem;
$font-size-large: 4.8rem;
$font-size-huge: 6rem;

$btn-default-color: #ffffff;
$btn-confirmation-agree: #299901;
$btn-confirmation-cancel: #A50000;


$main-backgound-color: #282c34;
$main-box-color: #47494A;
$secondary-box-color: rgba(156, 156, 156, 0.3);

$padding-small: 1.2rem;


$margin-between-main-group-elements: 2rem;
