/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


@import './styles/variables.scss';
@import './styles/mixin.scss';
@import './styles/components.scss';

html {
  font-size: 10px;
}
.App {
  text-align: center;
  background: $main-backgound-color;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  min-height: 100vh;
  background: #2B2A39;
}

.error {
  font-size: $font-size-normal;
  color: #F00;
}


button { cursor: pointer; }

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px; /* Регулирайте както е необходимо */
}

.green-dot {
  background-color: green;
}

.red-dot {
  background-color: red;
}

// Client Profile
.client-profile {
  @include flexStart(column, null);
  
  .client-header {
    @include flexCenter(row, null);
    gap: 1rem;
    margin-top: $margin-between-main-group-elements;
    margin-bottom: $margin-between-main-group-elements;
    .client-avatar {
      box-sizing: border-box;
      overflow: hidden;
      width: 16rem;
      height: 16rem;
      border-radius: 16rem;
      
      img{
        width: 100%;
      }
       input[type="file"] {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        cursor: inherit;
        display: block;
      }
    }
    .client-name {
      font-size: $font-size-between;
    }
  }
  .client-profile-info {
    width: 100%;
    // overflow: scroll;
    overflow: overlay;
    ul {
      @include flexStart(column, null);
      li {
        @include flexStart(row, null);
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 0.5rem;
        .info-icons-holder {
          @include flexCenter(row, null);
          font-size: $font-size-between;
          margin-left: 2rem;
          margin-bottom: 0px;
          margin-top: 0px;
        }

        .p-div-margin {
          margin-left: 3rem
        }


        .edit-icons-input-holder {
          display: flex;
          flex-wrap: wrap;
          margin-left: 2rem;
        }

        input {
          width: 15rem;
          height: 3rem;
          border-radius: 4rem;
          text-align: center;
          outline: none;
          border: none;
          align-self: center;
          color: white;
          background-color: $secondary-box-color;
          &::placeholder {
            font-size: $font-size-small;
          }
        }
      }
      
    }
    .client-target {
      @include flexCenter(column, $secondary-box-color);
      margin-bottom: 1rem;
      font-size: $font-size-between;
      h3{
        font-size: $font-size-medium;
      }
      p {
        font-size: $font-size-between;
        margin-top: 0;
      }
      .change-button-holder {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  .text-holder {
    width: 100%;
    height: auto;
    color: white;
    border-radius: 3rem;
    padding: 1rem;
    background-color: $secondary-box-color;
    font-size: $font-size-between;
  }

  .input-holder{
    @include flexCenter(row, null);
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
    
    
    input {
      width: 100%;
      height: 3rem;
      border-radius: 1.5rem;
      margin-left: 2rem;
      text-align: center;
      outline: none;
      border: none;
      background-color: $secondary-box-color;
      color: white;
      &::placeholder {
        font-size: $font-size-small;
        color: white;
        opacity: 0.4;
      }
    }
  }
}

// client cards


.client-cards-holder {
  @include flexCenter(column, null);
  margin-top: 2rem;
  
}

.client-card {
  width: 29rem;
  box-sizing: border-box;
  border-radius: 2rem;
  margin-bottom: 1rem;
  padding: 1rem;

  .card-top-part {
    @include flexCenter(row, null);
    justify-content: space-between;
    .card-logo {
      width: 5rem;
      height: 5rem;
    }
    .card-type{
      font-size: $font-size-medium;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .card-middle-part {
    @include flexCenter(row, null);
    justify-content: space-between;
  }
  h4 {
    font-size: $font-size-normal;
    margin: 0;
    line-height: 1.2;
  }
  .client-visits {
    @include flexCenter(row, null);
    justify-content: space-between;
    margin-top: 2rem;
    h3 {
      font-size: $font-size-normal;
      margin: 0
    }
    .card-visits {
      max-width: 15rem;
    }
    .data-mini {
      @include flexCenter(row, null);
      justify-content: space-evenly;
      flex-wrap: wrap;
      width: 14rem;
      p {
        font-size: $font-size-small;
        margin: 0;
      }
    }
    
  }

  
}

.choose-card {
  @include flexCenter(row, null);

  width: 29rem;
  border-radius: 3rem;
  margin-top: 1rem;
  justify-content: space-between;
  cursor: pointer;
}

.react-datepicker__input-container {
  margin-top: 0.5rem;
  input {
    width: 8rem;
    height: 3rem;
    background: $secondary-box-color;
    border-radius: 3rem;
    color: white;
    text-align: center;
  }
  
}

.card-btn {
  @include flexCenter(row, null);
  justify-content: space-between;
  margin-top: 2rem;
}

.create-card-overlay {
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 11;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  /* Note: backdrop-filter has minimal browser support */

  .client-card {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
  }
}



// Clients Dashboard
.clients-holder {
  @include flexCenter(column, $main-backgound-color);
  justify-content: flex-start;
  width: 100%;
  margin-top: 2rem;
  // height: 100vh;
  .search-bar {
    input {
      width: 100%;
      height: 3rem;
      border-radius: 1.5rem;
      text-align: center;
      outline: none;
      border: none;
      &::placeholder {
        font-size: $font-size-small;
      }
    }
  }
  .clients {
    @include flexCenter(row, null);
    margin-top: $margin-between-main-group-elements;
    flex-wrap: wrap;
    .client-holder {
      @include flexCenter(column, null);
      width: 29rem;
      height: 22rem;
      
      background-color: $secondary-box-color;
      border-radius: 2rem;
      margin-left: $margin-between-main-group-elements;
      margin-top: $margin-between-main-group-elements;
      .name-img-holder {
        @include flexCenter(row, null);
        width: 100%;
        justify-content: space-evenly;
      }
      img {
        width: 10rem;
        height: 10rem;
        border-radius: 5rem;
      }
    }
  }
}



.search-bar {
  width: 30rem;
  height: 3rem;
  border-radius: 1.5rem;
  margin-left: 2rem;
  text-align: center;
  outline: none;
  border: none;
  color: white;

  input {
    background-color: rgba(156, 156, 156, 0.3);
    color: white;
  }
}


// Avatar Picker
.avatar-images {
  @include flexCenter(row, null);
  flex-wrap: wrap;
}
.avatar-picked-image {
  @include flexCenter(column, null);

  img {
    max-width: 40rem;
  }
}


// Trainers Dashboard
.trainers-holder {
  @include flexCenter(column, $main-backgound-color);
  justify-content: flex-start;
  width: 29rem;
  height: auto;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 1rem;
  background-color: $main-box-color;
  border-radius: 2rem;

  .name-img-holder {
    @include flexCenter(row, null);
    width: 100%;
    justify-content: space-evenly;
  }
  img {
    width: 10rem;
    height: 10rem;
    border-radius: 5rem;
  }
  p {
    font-size: $font-size-normal;
  }
  .trainers-phone {
    @include flexCenter(row, null);
    justify-content: start;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    p {
      font-size: $font-size-normal;
      margin-left: 1rem;
    }
  }
   
}
