@import '../../../styles/variables.scss';

.user-info {
    font-size: $font-size-normal;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > span {
        display: block;
    }

    &__name {
        font-weight: $font-weight-bold;
    }

    &__picture {
        max-width: 18rem;
        margin-right: 3rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

    }
}