@import '../../styles/components.scss';
@import '../../styles/mixin.scss';
@import '../../styles/variables.scss';


/* ----------------- */
/* Home page trening type section */
.home-hero  {
  @include flexCenter(column, null);
    width: 100%;
    height: 60vh;
    background-image: url(../../assets/images/alfa_cover.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-position-y: top;

    h1 {
      font-size: $font-size-huge;
      font-weight: 600;
      line-height: 5rem;
      
    }
    .home-hero-info {
      font-size: $font-size-medium;
      margin-top: 2rem;
      font-weight: 200;

    }
}



.homepage-trening {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    background-color: $main-backgound-color;
  
    h1 {
      font-size: $font-size-medium;
    }
    .homepage-trening-type {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
    }
  
  }
  
  .flip-card {
    background-color: transparent;
    width: 30rem;
    height: 42rem;
    perspective: 100rem;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  
    h1 {
      color: white;
      line-height: 2.2rem;
    }
    p {
      margin-top: 2rem;
      font-size: $font-size-normal;
    }
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    @include flexCenter(column, null);
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    position: absolute;
    background-color: $main-box-color;
    color: white;
    transform: rotateY(180deg);
    top: 0;
  }
  .personal-training {
    width: 100%;
    height: 100%;
      background: url("../../assets/images/persona_treiner.jpg") no-repeat;
      background-size: cover;
  }
  
  .fit-kids {
    width: 100%;
    height: 100%;
      background: url("../../assets/images/fit_kids.jpg") no-repeat;
      background-size: cover;
  }
  
  .group-treiner {
    width: 100%;
    height: 100%;
      background: url("../../assets/images/group_treiner.jpg") no-repeat;
      background-size: cover;
  }
  
  .atletic-treiner {
    width: 100%;
    height: 100%;
      background: url("../../assets/images/atletic_treiner.jpg") no-repeat;
      background-size: cover;
  }
  